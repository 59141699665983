.welcome-container {
  display: flex;
  height: 100vh;
  background: #e4e9f2;
}

.welcome-sidebar {
  background: black;
  color: white;
  width: 30%;
}

.welcome-sidebar-content {
  margin-top: 100px;
  margin-left: 30px;
  margin-right: 30px;
}

.welcome-sidebar-content-numbers {
  color: #e32d2d;
}

.welcome-heading {
  display: flex;
  align-items: center;
}

.welcome-logo-image {
  width: 320px;
}

.welcome-logo {
  width: 80px;
  height: 120px;
  background: #e32d2d;
  border-radius: 5px;
  margin-right: 20px;
  margin-top: 10px;
}

.welcome-title {
  font-size: 70px;
  margin-bottom: -20px;
}

.welcome-image-placeholder {
  width: 200px;
  height: 250px;
  margin-top: 50px;
  margin-bottom: 50px;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.welcome-image {
  width: 280px;
  margin-top: 20px;
}

.welcome-content {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 20px;
}

.welcome-information {
  text-align: center;
  width: 600px;
}

.welcome-announcement {
  color: #e32d2d;
  background-color: white;
  padding: 14px;
  border-radius: 8px;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.welcome-paragraph {
  font-size: 14px;
}

.welcome-start-btn {
  width: 150px;
  height: 60px;
  background: #e32d2d;
  color: white;
  font-size: 22px;
  font-weight: bold;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 30px;
}

.powered-by-logo-container {
  position: absolute;
  color: white;
  bottom: 10px;
  left: 30px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.powered-by-logo-image {
  width: 110px;
  margin-bottom: 10px;
}

.party-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background: #e4e9f2;
}

.party-title {
  margin-top: 50px;
  margin-bottom: -70px;
  font-size: 50px;
}

.party-counter-container {
  display: flex;
  align-items: center;
  margin-bottom: -70px;
}

.party-decrement-btn {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: none;
  background: #d4cfcd;
  font-size: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.party-count {
  font-size: 140px;
  color: #e32d2d;
  margin-left: 100px;
  margin-right: 100px;
}

.party-increment-btn {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: none;
  background: #d4cfcd;
  font-size: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.party-information-container {
  display: flex;
  justify-content: center;
  gap: 40px;
  width: 70%;
}

.party-information-btn {
  width: 150px;
  height: 125px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  border-radius: 10px;
  font-size: 14px;
}

.party-information-btn1 {
  width: 150px;
  height: 125px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid #e32d2d;
  background: #e8c5c5;
  border-radius: 10px;
  font-size: 14px;
}

.party-arrow-container {
  width: 100px;
  height: 150px;
  justify-content: center;
  align-items: center;
}

.party-up-arrow {
  width: 0;
  height: 0;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-bottom: 30px solid black;
  position: relative;
  bottom: 55px;
}

.party-down-arrow {
  width: 0;
  height: 0;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-top: 30px solid black;
  position: relative;
  left: 18px;
  top: 5px;
}

.party-information-title {
  position: absolute;
  display: flex;
  flex-direction: column;
}

.party-information-counter {
  font-size: 14px;
  background: #e32d2d;
  color: white;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 30px;
  left: 30px;
}

.party-wait-summary-container {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.party-button-container {
  width: 80%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 20px;
}

.party-back-btn {
  width: 150px;
  border: 1px solid #e32d2d;
  color: #e32d2d;
  border-radius: 40px;
  font-size: 20px;
  font-weight: bold;
}

.party-next-btn0 {
  width: 150px;
  border: none;
  background: #d4cfcd;
  border-radius: 40px;
  font-size: 20px;
  font-weight: bold;
}

.party-next-btn1 {
  width: 150px;
  border: none;
  background: #e32d2d;
  color: white;
  border-radius: 40px;
  font-size: 20px;
  font-weight: bold;
}

.input-total-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #e4e9f2;
}

.input-name-container {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-left: 2px dashed black;
  margin-bottom: 100px;
}

.input-name-title {
  width: 300px;
  font-size: 28px;
  font-weight: 500;
  text-align: center;
}

.input-name-btn {
  width: 350px;
  height: 60px;
  border: 2px solid #e32d2d;
  border-radius: 40px;
  font-size: 22px;
  font-weight: 600;
  color: #e32d2d;
}

.input-or {
  background: #e4e9f2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  left: 12px;
}

.input-number-container {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 70px;
  margin-bottom: 100px;
}

.input-number-title {
  width: 300px;
  font-size: 28px;
  font-weight: 500;
  text-align: center;
}

.input-number-display {
  width: 350px;
  height: 50px;
  margin-top: -5px;
  border: 2px solid #d4cfcd;
  border-radius: 40px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  font-weight: 600;
  color: #8e9094;
  text-align: center;
  outline: none;
}

.input-number-display1 {
  width: 350px;
  height: 50px;
  margin-top: -5px;
  border: 2px solid #d4cfcd;
  border-radius: 40px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  font-weight: 600;
  color: #e32d2d;
  text-align: center;
  outline: none;
}

.input-numbers {
  display: grid;
  grid-template-columns: 75px 75px 75px;
  grid-template-rows: 75px 75px 75px 75px;
  width: 55%;
  margin-top: 25px;
  gap: 15px 30px;
  justify-content: center;
  margin-top: 20px;
}

.number-btn {
  background: #d4cfcd;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 32px;
  border: none;
}

.input-button-container {
  width: 80%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 20px;
}

.input-back-btn {
  width: 150px;
  border: 1px solid #e32d2d;
  color: #e32d2d;
  border-radius: 40px;
  font-size: 20px;
  font-weight: bold;
}

.input-next-btn1 {
  width: 200px;
  border: none;
  background: #e32d2d;
  color: white;
  border-radius: 40px;
  font-size: 20px;
  font-weight: bold;
}

.input-next-btn0 {
  width: 200px;
  border: none;
  background: #d4cfcd;
  border-radius: 40px;
  font-size: 20px;
  font-weight: bold;
}

.name-total-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #e4e9f2;
}

.name-input-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
}

.name-input {
  width: 320px;
  height: 70px;
  background: #e4e9f2;
  border: 2px solid #d4cfcd;
  border-radius: 70px;
  text-align: center;
  outline: none;
  font-size: 24px;
}

.ticket-total-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #e4e9f2;
}

.ticket-number-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 150px;
  background: #d4cfcd;
  border-radius: 20px;
  font-size: 50px;
  color: #e32d2d;
  margin-top: 20px;
  margin-bottom: 20px;
}

.ticket-finish-btn {
  width: 220px;
  height: 70px;
  border: none;
  background: #e32d2d;
  color: white;
  border-radius: 40px;
  font-size: 24px;
  font-weight: bold;
  margin-top: 20px;
}

.dashboard-total-container {
  min-height: 100vh;
  background: #e4e9f2;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  padding-bottom: 20px;
}

.dashboard-navbar-container {
  width: 100%;
  height: 140px;
  background: #02031c;
  display: flex;
}

.dashboard-logo-container {
  width: 30%;
  height: 70%;
  align-self: flex-end;
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  margin-left: 25px;
}

.navbar-logo-image {
  width: 210px;
}

.dashboard-logo {
  width: 55px;
  height: 80px;
  background: #e32d2d;
  border-radius: 5px;
}

.dashboard-logo-title-container {
  color: white;
  margin-left: 10px;
}

.dashboard-logo-title {
  margin-bottom: -15px;
}

.navigation-btn {
  width: 155px;
  height: 65px;
  align-self: flex-end;
  border: none;
  border-radius: 8px 8px 0px 0px;
  margin-right: 4px;
  background: #4d4f57;
  color: white;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.navigation-btn1 {
  width: 155px;
  height: 65px;
  align-self: flex-end;
  border: none;
  border-radius: 8px 8px 0px 0px;
  margin-right: 4px;
  background: #e4e9f2;
  color: #4d4f57;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.dashboard-icon-placeholder {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  align-self: flex-end;
  margin-left: 20px;
  margin-bottom: 20px;
  color: white;
}

.wait-time-container {
  width: 90%;
  height: 125px;
  margin-top: 35px;
  background: white;
  border-radius: 10px;
  border: 1px solid #dcdedc;
  display: flex;
  position: relative;
}

.wait-time-summary {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.wait-time-title-container {
  position: fixed;
  top: -2px;
  left: 130px;
}

.wait-time-title-container2 {
  position: fixed;
  top: -2px;
  left: 608px;
}

@media only screen and (max-device-width: 1180px) and (orientation: landscape) {
  .wait-time-title-container {
    position: fixed;
    top: -2px;
    left: 95px;
  }

  .wait-time-title-container2 {
    position: fixed;
    top: -2px;
    left: 455px;
  }

  .dashboard-icon-placeholder {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    align-self: flex-end;
    margin-left: 210px;
    margin-bottom: 20px;
    color: white;
  }
}

@media only screen and (max-device-width: 820px) and (orientation: portrait) {
  .wait-time-title-container {
    position: fixed;
    top: -2px;
    left: 45px;
  }

  .wait-time-title-container2 {
    position: fixed;
    top: -2px;
    left: 245px;
  }
}

@media only screen and (max-device-width: 1024px) and (orientation: landscape) {
  .wait-time-title-container {
    position: fixed;
    top: -2px;
    left: 74px;
  }

  .wait-time-title-container2 {
    position: fixed;
    top: -2px;
    left: 366px;
  }

  .dashboard-icon-placeholder {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    align-self: flex-end;
    margin-left: 120px;
    margin-bottom: 20px;
    color: white;
  }
}

@media only screen and (max-device-width: 768px) and (orientation: portrait) {
  .wait-time-title-container {
    position: fixed;
    top: -2px;
    left: 40px;
  }

  .wait-time-title-container2 {
    position: fixed;
    top: -2px;
    left: 214px;
  }

  .dashboard-icon-placeholder {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    align-self: flex-end;
    margin-left: 18px;
    margin-bottom: 20px;
    color: white;
  }

  .dashboard-logo-container {
    width: 26%;
    height: 70%;
    align-self: flex-end;
    display: flex;
    align-items: center;
    margin-bottom: 0px;
    margin-left: 25px;
  }

  .navbar-logo-image {
    width: 170px;
  }
}

.wait-time-subtitle {
  margin-bottom: 0px;
  color: #7d7d7d;
}

.wait-time-party-number {
  margin-top: 10px;
  color: #e32d2d;
}

.expected-wait-time-container {
  width: 70%;
  display: flex;
  justify-content: space-around;
  margin-right: 20px;
}

.expected-wait-time-container-values {
  display: flex;
}

.party-of {
  display: flex;
  width: 110px;
  flex-direction: column;
  justify-content: center;
}

.party-of-title {
  color: #7d7d7d;
  margin-bottom: 5px;
}

.wait-number {
  margin-top: 5px;
}

.wait-mins {
  font-size: 12px;
  margin-left: 5px;
}

.dashboard-table-header-container {
  width: 90%;
  height: 70px;
  margin-top: 50px;
  background: white;
  border-radius: 10px;
  border: 1px solid #dcdedc;
  display: flex;
  align-items: center;
}

.header1 {
  color: #7d7d7d;
  width: 8%;
  margin-left: 20px;
}

.header2 {
  color: #7d7d7d;
  width: 18%;
}

.header2-name {
  margin-bottom: 0px;
}

.header2-number {
  margin-top: 4px;
  font-size: 12px;
}

.header3 {
  color: #7d7d7d;
  width: 10%;
}

.header4 {
  color: #7d7d7d;
  width: 14%;
  display: flex;
  align-items: center;
}

.header5 {
  color: #7d7d7d;
  width: 8%;
  display: flex;
  align-items: center;
}

.header6 {
  color: #7d7d7d;
  width: 40%;
}

.dashboard-table-row {
  width: 90%;
  height: 80px;
  background: white;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.header6-btn-container {
  width: 42%;
  height: 40px;
  display: flex;
  align-items: center;
}

.alert-btn {
  width: 100px;
  height: 40px;
  background: #e32d2d;
  color: white;
  font-size: 14px;
  font-weight: bold;
  border: none;
  border-radius: 50px;
  margin-right: 10px;
}

.alert-btn-notified {
  width: 100px;
  height: 40px;
  border: 1px solid #e32d2d;
  background: white;
  color: #e32d2d;
  font-size: 12px;
  font-weight: bold;
  border-radius: 50px;
  margin-right: 10px;
}

.seat-btn {
  width: 100px;
  height: 40px;
  background: #e32d2d;
  color: white;
  font-size: 14px;
  font-weight: bold;
  border: none;
  border-radius: 50px;
  margin-right: 5px;
}

.note-btn {
  border: none;
  background: white;
  color: #7d7d7d;
  font-size: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.note {
  border: none;
  background: white;
  color: #e32d2d;
  font-size: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.modal-note-input {
  margin-top: 20px;
}

.modal-box {
  width: 100%;
  height: 100%;
  position: relative;
}

.modal-close {
  position: absolute;
  top: -20px;
  right: -20px;
}

.modal-save-btn {
  margin-top: 25px;
  display: flex;
  justify-content: end;
}

.delete-btn {
  border: none;
  background: white;
  color: #7d7d7d;
  font-size: 24px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
}

.archive-total-container {
  min-height: 100vh;
  background: #e4e9f2;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  padding-bottom: 20px;
}

.archive-table-header-container {
  width: 90%;
  height: 70px;
  margin-top: 50px;
  background: white;
  border-radius: 10px;
  border: 1px solid #dcdedc;
  display: flex;
  align-items: center;
}

.archive-header1 {
  color: #7d7d7d;
  width: 10%;
  margin-left: 20px;
}

.archive-header2 {
  color: #7d7d7d;
  width: 16%;
}

.archive-header3 {
  color: #7d7d7d;
  width: 10%;
}

.archive-header4 {
  color: #7d7d7d;
  width: 15%;
}

.archive-header5 {
  color: #7d7d7d;
  width: 15%;
}

.archive-header6 {
  color: #7d7d7d;
  width: 32%;
}

.archive-table-row {
  width: 90%;
  height: 80px;
  background: white;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.put-back-btn {
  width: 140px;
  height: 40px;
  border: 1px solid #e32d2d;
  background: white;
  color: #e32d2d;
  font-size: 18px;
  font-weight: bold;
  border-radius: 50px;
  margin-right: 10px;
}

.settings-total-container {
  min-height: 100vh;
  background: #e4e9f2;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
}

.settings-container {
  width: 90%;
  margin-top: 50px;
  background: white;
  border-radius: 20px;
  border: 1px solid #dcdedc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.settings-inputs-title {
  width: 80%;
  font-size: 20px;
}

.settings-inputs-container {
  width: 80%;
  display: flex;
  justify-content: center;
  column-gap: 50px;
  flex-wrap: wrap;

}

.input-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  width: 40%;
}

.input-announcement-container {
  display: flex;
  flex-direction: column;
  width: 88%;
}



.settings-label {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 5px;
}

.settings-input {
  border: none;
  padding: 10px 12px;
  background: #e4e9f2;
  border-radius: 4px;
}

.settings-announcement-title-span {
  font-size: 12px;
}

.settings-announcement-input {
  border: none;
  padding: 10px 12px;
  background: #e4e9f2;
  border-radius: 4px;
  height: 40px;
}

.settings-save-btn-container {
  width: 90%;
  display: flex;
  justify-content: flex-end;
}


.settings-title {
  font-size: 40px;
  margin-bottom: 0px;
  text-align: center;
  color: #292929;
}

.settings-subtitle {
  font-size: 20px;
  color: #292929;
  text-align: center;
}

.settings-horizontal-line {
  width: 80%;
  background: #ababab;
}

.settings-party-input-container {
  width: 80%;
  color: #292929;
}

.settings-party-input-container-title {
  font-size: 20px;
}

.settings-party-input-sub-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.remote-checkin-toggle-container {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  margin-top: 10px;
  margin-bottom: 25px;
}

.settings-toggle-title {
  font-size: 20px;
  color: #292929;
}

.toggle-design {
  width: 60px;
  height: 35px;
  background: #30c940;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.toggle-design1 {
  width: 60px;
  height: 35px;
  background: gray;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.toggle-design-ball {
  width: 30px;
  height: 30px;
  background: white;
  border-radius: 50%;
  align-self: flex-end;
  margin-right: 2px;
}

.toggle-design-ball1 {
  width: 30px;
  height: 30px;
  background: white;
  border-radius: 50%;
  align-self: flex-start;
  margin-left: 2px;
}

.sign-out-btn {
  width: 150px;
  height: 50px;
  background: #e32d2d;
  color: white;
  font-size: 18px;
  font-weight: bold;
  border: none;
  border-radius: 50px;
  margin-top: 30px;
  margin-bottom: 50px;
  align-self: center;
}

.settings-save-btn {
  border: 2px solid #e32d2d;
  color: #e32d2d;
  background: white;
  width: 125px;
  height: 40px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 50px;
  margin-top: 5px;
  margin-bottom: 50px;
  align-self: center;
}

.annoucement-save-btn {
  border: 2px solid #e32d2d;
  color: #e32d2d;
  background: white;
  width: 125px;
  height: 40px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 50px;
  margin-top: 30px;
  margin-bottom: 50px;
  align-self: center;
}
